import React from "react";
import { faHashtag, faLock } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TAG_COLOR_MAP } from "../../../constants";
import Link from "../../theme/3.0/Link";

export const CategoryTags = ({ item }) => {
    const items = [];
    if (item.access?.indexOf("Members") >= 0) {
        items.push({
            name: "Members",
            icon: faLock,
            color: "#fff",
            bgColor: "#95ce58"
        });
    }
    const metaDataTags: [
        { sys: { id: string } }
    ] = item.metadata.tags.filter((tag) =>
        tag?.sys?.id?.startsWith("category")
    );

    if (metaDataTags.length) {
        metaDataTags.map((tag) => {
            items.push({
                name: tag.sys.id
                    .replace("category", "")
                    .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, "")
                    .replace(
                        /([a-z])([A-Z])/g,
                        (m, a, b) => `${a}_${b.toLowerCase()}`
                    )
                    .replace(/[^A-Za-z0-9]+|_+/g, " ")
                    .toLowerCase()
                    .replace(
                        /( ?)(\w+)( ?)/g,
                        (m, a, b, c) =>
                            a + b.charAt(0).toUpperCase() + b.slice(1) + c
                    ),
                icon: faHashtag,
                color: "#fff",
                bgColor: TAG_COLOR_MAP[tag.sys.id]
            });
        });
    }
    const tags = items.length
        ? items.map((tag) => {
              return (
                  <div
                      className="badge"
                      style={{ backgroundColor: tag.bgColor, color: tag.color }}
                  >
                      <FontAwesomeIcon icon={tag.icon as IconProp} /> {tag.name}
                  </div>
              );
          })
        : null;
    // console.log({ tags });
    return tags;
};

export const FilterTag = ({ name }: { name: string }) => {
    const tag = {
        name: name
            .replace("category", "")
            .replace(/^[^A-Za-z0-9]*|[^A-Za-z0-9]*$/g, "")
            .replace(/([a-z])([A-Z])/g, (m, a, b) => `${a}_${b.toLowerCase()}`)
            .replace(/[^A-Za-z0-9]+|_+/g, " ")
            .toLowerCase()
            .replace(
                /( ?)(\w+)( ?)/g,
                (m, a, b, c) => a + b.charAt(0).toUpperCase() + b.slice(1) + c
            ),
        icon: faHashtag,
        id: name,
        color: "#fff",
        bgColor: TAG_COLOR_MAP[name]
    };

    return (
        <div
            key={name}
            className="badge"
            style={{ backgroundColor: tag.bgColor, color: tag.color }}
        >
            <FontAwesomeIcon icon={tag.icon as IconProp} />
            <Link
                target={""}
                // url={"#library"}
                routeKey={"event-calendar-library"}
                query={{ tag: tag.id }}
                label={tag.name}
                size={"sm"}
                className={"text-white"}
            >
                {/* {tag.name} */}
            </Link>
        </div>
    );
};
