/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import Head from "next/head";
import PropTypes from "prop-types";
import React from "react";
import Breadcrumb from "../theme/3.0/Page/Nav/Breadcrumb";
import theme from "../theme/3.0/theme";
import Container from "./Container";
import Footer from "./Footer";
import Header from "./Header";
// Layout
import Page from "./Page";

type Props = {
    title;
    pageTitle;
    includePageTitleTag;
    breadcrumbs?;
    description;
    currentRouteKey?;
    children;
};

const BasicPage = ({
    title,
    pageTitle,
    includePageTitleTag,
    breadcrumbs,
    description,
    currentRouteKey,
    children
}: Props) => {
    if (typeof pageTitle === "undefined") {
        pageTitle = title;
    }

    if (typeof includePageTitleTag === "undefined") {
        includePageTitleTag = true;
    }

    let finalPageTitle =
        pageTitle + (includePageTitleTag === true ? " | Convergence" : "");

    return (
        <Page>
            <Head>
                <title>{finalPageTitle}</title>
                <meta name="og:title" content={finalPageTitle} />
                {description && (
                    <meta name="description" content={description} />
                )}
            </Head>

            <Header currentRouteKey={currentRouteKey} />

            <div id={"content"}>
                {title && (
                    <div className={"page-title"}>
                        <Container>
                            <h2>{title}</h2>
                        </Container>
                    </div>
                )}

                {breadcrumbs && (
                    <div className={"page-breadcrumbs"}>
                        <Container>
                            <Breadcrumb breadcrumbs={breadcrumbs} />
                        </Container>
                    </div>
                )}
                {children}
            </div>

            <Footer
                // TS FOUND ERROR: no key className
                // @ts-ignore
                className="bottom"
            />

            <style jsx global>{`
                .page-title {
                    background-color: ${theme.colour.gray100};
                }
                .page-title + .page-breadcrumbs {
                    border-top: 1px solid ${theme.colour.gray200};
                }
                .page-title h2 {
                    font-size: 1.5rem;
                    padding: 1rem 0;
                    margin-bottom: 0;
                    -webkit-font-smoothing: antialiased;
                }
                .page-breadcrumbs {
                    position: sticky;
                    top: 60px;
                    z-index: 50;
                    font-size: 0.8rem;
                    padding: 0.5rem 0;
                    border-bottom: 1px solid ${theme.colour.gray200};
                    background-color: ${theme.colour.white};
                    overflow-y: hidden;
                    white-space: nowrap;
                }
                #content {
                    margin-top: -90px;
                }
                #content > div:first-child,
                #content > section:first-child > div:first-child {
                    margin-top: 45px;
                    padding-top: 45px;
                }
                // #content > div:first-child,
                // #content > section {
                //     padding-top: 90px;
                // }
            `}</style>
        </Page>
    );
};

BasicPage.propTypes = {
    children: PropTypes.any.isRequired,
    title: PropTypes.string,
    pageTitle: PropTypes.string,
    includePageTitleTag: PropTypes.bool,
    description: PropTypes.string
};

export default BasicPage;
