/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import { ContentfulService } from "../../service/ContentfulService";
import {
    b64DecodeUnicode,
    b64EncodeUnicode,
    toContentfulFilters
} from "../../utils/CommonUtils";
import { PAGE_KNOWLEDGE } from "../../constants";

export type BaseContentfulPagedListingProps = {};

type State = {
    loading?: boolean;
    loadingMore?;
    articles?;
    filteredArticles?;
    disableLoadMore?;
    skip?;
    limit?;
    contentful?;
};
// https://stackoverflow.com/questions/39123667/react-typescript-extending-a-component-with-additional-properties
class BaseContentfulPagedListing<
    P extends BaseContentfulPagedListingProps = BaseContentfulPagedListingProps
> extends React.Component<P, State> {
    contentfulService: ContentfulService;
    availableFilters: {};
    contentType: string;
    defaultFilter: string;
    permanentFilters: {};
    constructor(props) {
        super(props);
        this.contentfulService = new ContentfulService();

        this.availableFilters = {};

        this.contentType = "";

        this.defaultFilter = "";

        this.permanentFilters = {};

        this.state = {
            loadingMore: false,
            disableLoadMore: false,
            loading: true,
            skip: 0,
            limit: 12,
            contentful: {
                orderDirection: "DESC",
                orderByField: "fields.date",
                query: "",
                filters: {}
            },
            filteredArticles: {},
            articles: []
        };
    }

    getQueryData = () => {
        let variables: { keyword?: string; filters?: any; sort?; tag? } = {
            keyword: "",
            filters: "",
            sort: ""
        };
        if (process.browser) {
            let queryVars = window.location.search.replace("?", "&").split("&");
            queryVars.map((query) => {
                if (query.length <= 0) {
                    return;
                }

                let split = query.split("=");

                if (split[0] && split[1]) {
                    variables[split[0]] = decodeURIComponent(split[1]);
                }
            });

            if (variables.filters) {
                try {
                    variables.filters = JSON.parse(
                        // @ts-ignore
                        b64DecodeUnicode(decodeURIComponent(variables.filters))
                    );
                } catch (e) {
                    //
                }
            }

            if (variables.sort) {
                try {
                    variables.sort = JSON.parse(
                        b64DecodeUnicode(decodeURIComponent(variables.sort))
                    );
                } catch (e) {
                    //
                }
            }

            if (variables.keyword) {
                try {
                    variables.keyword = b64DecodeUnicode(
                        decodeURIComponent(variables.keyword)
                    );
                } catch (e) {
                    //
                }
            }
        }
        return variables;
    };

    setPushState = (filters, keyword) => {
        let serializedFilters = filters
            ? b64EncodeUnicode(JSON.stringify(filters))
            : null;
        let serializedKeyword =
            keyword.length > 0 ? b64EncodeUnicode(keyword) : null;

        let queryString = [];
        if (serializedKeyword) {
            queryString.push(
                `keyword=${encodeURIComponent(serializedKeyword)}`
            );
        }

        if (serializedFilters) {
            queryString.push(
                `filters=${encodeURIComponent(serializedFilters)}`
            );
        }

        window.history.pushState(
            {},
            "",
            queryString.length > 0 ? `?${queryString.join("&")}` : "?"
        );
    };

    componentDidMount() {
        const { contentful, limit, skip } = this.state;
        this.fetchArticles(contentful, skip, limit);

        window.addEventListener("scroll", this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, false);
    }

    onScroll = () => {
        if (
            window.innerHeight + window.scrollY >=
                document.body.offsetHeight - 300 &&
            this.state.articles.length &&
            !this.state.loadingMore &&
            !this.state.disableLoadMore
        ) {
            this.loadMore();
        }
    };

    fetchArticles(contentful, skip, limit, append = false) {
        let orderDirection = contentful.orderDirection === "DESC" ? "-" : "";
        let order = orderDirection + contentful.orderByField;
        let filters = toContentfulFilters(contentful.filters);

        let options: { order; query? } = {
            order
        };

        if (contentful.query !== "") {
            options.query = contentful.query;
        }

        if (filters) {
            options = {
                ...options,
                ...filters,
                ...this.permanentFilters
            };
        }
        const featuredOptions = {
            ...options,
            ...{ "fields.featured": "Yes" }
        };
        if (!append) {
            this.setState({ loading: true });
        } else {
            this.setState({ loadingMore: true });
        }
        const query = async () => {
            let filteredOptions = { ...options };
            let filteredArticles = this.state.filteredArticles;
            let featuredArticles = [];
            if (!append && this.contentType === PAGE_KNOWLEDGE) {
                featuredArticles = await this.contentfulService
                    .getArticles(this.contentType, 4, skip, featuredOptions)
                    .then(({ total, articles: featuredArticles }) => {
                        filteredArticles = {
                            "sys.id[nin]": featuredArticles
                                .map((a) => a.id)
                                .join(",")
                        };
                        filteredOptions = {
                            ...options,
                            ...filteredArticles
                        };
                        // const featuredSkip = skip + featuredArticles.length;
                        // this.setState({ skip: featuredSkip, filteredArticles });
                        this.setState({ filteredArticles });

                        return featuredArticles;
                    });
            }
            // if we didn't just get featured articles, set the filter from state
            if (featuredArticles.length === 0) {
                filteredOptions = {
                    ...options,
                    ...filteredArticles
                };
            }

            return await this.contentfulService
                .getArticles(this.contentType, limit, skip, filteredOptions)
                .then(({ total, articles }) => {
                    let disableLoadMore = articles.length < limit;
                    this.setState((prevState) => {
                        let updateArticles = [];
                        if (append) {
                            updateArticles = [
                                ...prevState.articles,
                                ...articles
                            ];
                        } else {
                            updateArticles = [...featuredArticles, ...articles];
                        }
                        return {
                            total,
                            articles: updateArticles,
                            disableLoadMore
                        };
                    });
                });
        };
        return query().then(() => {
            this.setState({ loading: false, loadingMore: false });
        });
    }

    loadMore = () => {
        this.setState(
            ({ skip, limit }) => {
                let newSkip = parseInt(skip) + parseInt(limit);
                return {
                    skip: newSkip,
                    loadingMore: true
                };
            },
            () =>
                this.fetchArticles(
                    this.state.contentful,
                    this.state.skip,
                    this.state.limit,
                    true
                )
        );
    };

    updateState = (newState) => {
        this.setState(newState, () => {
            this.setPushState(
                this.state.contentful.filters,
                this.state.contentful.query
            );
            this.fetchArticles(this.state.contentful, 0, this.state.limit);
        });
    };
}

export default BaseContentfulPagedListing;
