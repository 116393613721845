/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import debounce from "lodash.debounce";
// import PropTypes from "prop-types";
import React from "react";
import FilterButton from "../general/FilterButton/FilterButton";
import FilterButtonActiveFilters from "../general/FilterButton/FilterButtonActiveFilters";
import SearchButton from "../general/SearchButton";
import SortButton from "../general/SortButton";
import { BRAND_COLOURS } from "../../constants";
import ReactSelect from "../layout/ReactSelect";
import Select, { StylesConfig, components } from "react-select";
import { FilterTag } from "../page/capacity-building/Tags";

type Props = {
    initialFilters?;
    availableFilters;
    updateState;
    toContentfulFilters;
    enableSortButton?;
    contentful?;
    defaultFilter;
    // filterModalColumnHeaders;
    filterTags?;
    disableSearch?;
    isMulti?;
};
type State = {
    filters;
    keyword;
};

const { ValueContainer: OriginalValueContainer, Placeholder } = components;

const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children;

    return (
        // @ts-ignore
        <OriginalValueContainer {...props}>
            {/* @ts-ignore */}
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {values}
            {input}
        </OriginalValueContainer>
    );
};

const selectStyles: StylesConfig = {
    menu: (provided) => ({
        ...provided,
        fontSize: 14,
        zIndex: 9999
    }),
    groupHeading: (provided) => ({
        ...provided,
        color: "blue",
        fontWeight: 600
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999
    }),
    // move the placeholder label upon select
    container: (provided, state) => ({
        ...provided,
        padding: 4,
        paddingBottom: "1.5rem",
        ...(state.hasValue || state.selectProps.inputValue ? {} : {}),
        transition: "margin-top 0.1s"
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        fontSize: 15,
        paddingRight: 24,
        overflow: "visible"
    }),
    option: (provided, { data }) => {
        return {
            ...provided,
            // @ts-ignore
            fontWeight: data.groupHeader ? 600 : 400
        };
    },
    multiValue: (provided, { data }) => {
        return {
            ...provided,
            // @ts-ignore
            fontWeight: data.groupHeader ? 600 : 400
        };
    },
    multiValueLabel: (provided, { data }) => {
        return {
            ...provided,
            // @ts-ignore
            fontWeight: data.groupHeader ? 600 : 400
        };
    },
    placeholder: (provided, state) => {
        return {
            ...provided,
            ...(state.hasValue || state.selectProps.inputValue
                ? {
                      position: "absolute"
                  }
                : {}),
            top: state.hasValue || state.selectProps.inputValue ? -25 : "50%",
            left:
                state.hasValue || state.selectProps.inputValue ? 0 : "initial",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 14
        };
    },
    control: (base, props) => {
        return {
            ...base,
            ...(props.hasValue || props.selectProps.inputValue ? {} : {})
        };
    }
};

class PagedContentfulFilterBar extends React.Component<Props, State> {
    initialFilters: any;
    updateParentStateDebounced: any;

    constructor(props) {
        super(props);
        this.initialFilters = this.props.initialFilters
            ? this.props.initialFilters
            : {};

        for (let filterKey in this.props.availableFilters) {
            if (
                Object.prototype.hasOwnProperty.call(
                    this.props.availableFilters,
                    filterKey
                ) &&
                !this.props.initialFilters?.[filterKey]
            ) {
                this.initialFilters[filterKey] = this.props.availableFilters[
                    filterKey
                ].default;
            }
        }

        this.state = {
            keyword: this.props.contentful.query,
            filters: this.initialFilters
        };

        this.updateParentStateDebounced = debounce(
            this.updateParentState,
            500,
            {
                leading: false,
                trailing: true
            }
        );
    }

    onClickSort = () => {
        this.updateParentState(({ contentful }) => {
            let orderDirection =
                contentful.orderDirection === "DESC" ? "ASC" : "DESC";
            return {
                contentful: {
                    ...contentful,
                    orderDirection
                }
            };
        });
    };

    onChangeKeyword = (keyword) => {
        this.setState({ keyword }, () => {
            this.updateParentStateDebounced(({ contentful }) => {
                return {
                    contentful: {
                        ...contentful,
                        query: keyword
                    }
                };
            });
        });
    };

    onClickSearchClose = () => {
        this.onChangeKeyword("");
    };

    onChange = (newValue, actionMeta) => {
        let filters = this.state.filters;
        if (Array.isArray(newValue)) {
            filters[actionMeta["name"]] =
                newValue.length > 0
                    ? newValue.map((v) => v.id)
                    : this.props.availableFilters[actionMeta["name"]].default;
        } else {
            filters[actionMeta["name"]] = newValue?.id
                ? [newValue?.id]
                : this.props.availableFilters[actionMeta["name"]].default;
        }

        this.setState({ filters }, () => {
            this.props.updateState(({ contentful }) => {
                return {
                    skip: 0, // reset skip when filters are reset
                    contentful: {
                        ...contentful,
                        filters: {
                            ...filters
                        }
                    }
                };
            });
        });

        return true;
    };
    onSubmitFilters = (filters) => {
        if (
            filters.impact_themes &&
            filters.impact_themes.length < 1 &&
            filters.instrumentFocus &&
            filters.instrumentFocus.length < 1 &&
            filters.knowledge_type &&
            filters.knowledge_type.length < 1 &&
            filters.regions &&
            filters.regions.length < 1 &&
            filters.sectors_and_sub_sectors &&
            Object.keys(filters.sectors_and_sub_sectors).length < 1
        ) {
            return;
        }

        let contentfulFilters = this.toContentfulFilters(filters);

        this.setState({ filters }, () => {
            this.props.updateState(({ contentful }) => {
                return {
                    skip: 0, // reset skip when filters are reset
                    contentful: {
                        ...contentful,
                        filters: {
                            ...contentfulFilters
                        }
                    }
                };
            });
        });

        return true;
    };

    onClickClearFilter = (event) => {
        const filterKey = event.target.getAttribute("data-filter");
        this.setState(
            ({ filters }) => {
                let filter = this.props.availableFilters[filterKey];
                filters[filterKey] = filter.default;
                return {
                    filters
                };
            },
            () => {
                let filters = this.state.filters;
                this.props.updateState(({ contentful }) => {
                    return {
                        contentful: {
                            ...contentful,
                            filters: {
                                ...filters
                            }
                        }
                    };
                });
            }
        );
    };

    toContentfulFilters = (filters) => {
        return this.props.toContentfulFilters(filters);
    };

    updateParentState = (...state) => {
        const { updateState } = this.props;
        return updateState(...state);
    };

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {}

    render() {
        const {
            enableSortButton,
            contentful,
            availableFilters,
            filterTags,
            disableSearch,
            isMulti = true
        } = this.props;
        const { filters, keyword } = this.state;

        return (
            <React.Fragment>
                <style jsx>
                    {`
                        .active-filters {
                            white-space: nowrap;
                            height: 100%;
                            margin-left: 10px;
                        }
                    `}
                </style>
                <div className="filters-bar">
                    {enableSortButton && (
                        <React.Fragment>
                            <SortButton
                                direction={contentful.orderDirection}
                                onClick={this.onClickSort}
                                className={"btn btn-dark float-right"}
                            />
                        </React.Fragment>
                    )}
                    <div className="row no-gutters">
                        <div className="col-12">
                            <label className="sr-only" htmlFor="search">
                                Search
                            </label>
                            <div className="d-flex input-group justify-content-start">
                                {!disableSearch && (
                                    <SearchButton
                                        value={keyword}
                                        onChange={this.onChangeKeyword}
                                        onClickClose={this.onClickSearchClose}
                                        showSearch
                                    />
                                )}
                                <span>&nbsp;</span>
                                {Object.keys(this.props.availableFilters)
                                    .length > 0 && (
                                    <React.Fragment>
                                        {Object.keys(availableFilters)?.map(
                                            (key: string) => {
                                                const filter =
                                                    availableFilters[key];
                                                const initialFilter =
                                                    this.props.initialFilters?.[
                                                        key
                                                    ] || [];
                                                const options: [string] | {} =
                                                    filter?.options?.() || [];
                                                const selectOptions = Array.isArray(
                                                    options
                                                )
                                                    ? options.map((o) => ({
                                                          label: o,
                                                          name: o,
                                                          selected:
                                                              Object.keys(
                                                                  initialFilter
                                                              ).length &&
                                                              initialFilter.indexOf(
                                                                  o
                                                              ) > -1,
                                                          id: o
                                                      }))
                                                    : Object.keys(
                                                          options
                                                      ).reduce((p, c) => {
                                                          const groupOptions = options[
                                                              c
                                                          ].map((o) => ({
                                                              name: `  ${o}`,
                                                              selected:
                                                                  Object.keys(
                                                                      initialFilter
                                                                  ).length &&
                                                                  initialFilter.indexOf(
                                                                      o
                                                                  ) > -1,
                                                              id: o
                                                          }));
                                                          return [
                                                              ...p,
                                                              {
                                                                  label: c,
                                                                  value: c,
                                                                  name: c,
                                                                  id: c,
                                                                  selected:
                                                                      Object.keys(
                                                                          initialFilter
                                                                      )
                                                                          .length &&
                                                                      initialFilter.indexOf(
                                                                          c
                                                                      ) > -1,
                                                                  groupHeader: true
                                                              },
                                                              {
                                                                  options: groupOptions
                                                              }
                                                          ];
                                                      }, []);
                                                const selectedFlatOptions = selectOptions.reduce(
                                                    (p, o) => {
                                                        if (o.selected) {
                                                            return [...p, o];
                                                        }
                                                        const groupOptions = o.options?.filter(
                                                            (og) => {
                                                                if (
                                                                    og.selected
                                                                ) {
                                                                    return og.selected;
                                                                }
                                                            }
                                                        );
                                                        if (
                                                            groupOptions?.length
                                                        ) {
                                                            return [
                                                                ...p,
                                                                ...groupOptions
                                                            ];
                                                        }
                                                        return p;
                                                    },
                                                    []
                                                );

                                                return (
                                                    <Select
                                                        isMulti={isMulti}
                                                        isClearable
                                                        hideSelectedOptions={
                                                            false
                                                        }
                                                        components={{
                                                            ValueContainer
                                                        }}
                                                        name={key}
                                                        options={selectOptions}
                                                        placeholder={
                                                            filter.label
                                                        }
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.name}
                                                        getOptionValue={(
                                                            option
                                                        ) => option.id}
                                                        onChange={this.onChange}
                                                        styles={selectStyles}
                                                        // menuPosition={"fixed"}
                                                        // controlShouldRenderValue={
                                                        //     false
                                                        // }
                                                        defaultValue={
                                                            selectedFlatOptions
                                                        }
                                                        // value={selectedOptions}
                                                        formatGroupLabel={(
                                                            group
                                                        ) => (
                                                            <div className="text-dark">
                                                                {group.label}
                                                            </div>
                                                        )}
                                                    />
                                                );
                                            }
                                        )}
                                        {/* <FilterButton
                                            defaultFilter={
                                                this.props.defaultFilter
                                            }
                                            availableFilters={
                                                this.props.availableFilters
                                            }
                                            filters={filters}
                                            onSubmit={this.onSubmitFilters}
                                            renderColumnHeaders={
                                                this.props
                                                    .filterModalColumnHeaders
                                            }
                                        /> */}
                                        <span>&nbsp;</span>
                                        {/* <FilterButtonActiveFilters
                                            filters={filters}
                                            availableFilters={
                                                this.props.availableFilters
                                            }
                                            onClickClearFilter={
                                                this.onClickClearFilter
                                            }
                                        /> */}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="col-auto">
                            {filterTags?.length && (
                                <>
                                    Filter To:{" "}
                                    {filterTags.map((tag) => (
                                        <FilterTag name={tag} />
                                    ))}
                                </>
                            )}
                        </div>
                        <div className="col-auto">
                            <div className="d-flex active-filters" />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PagedContentfulFilterBar;
